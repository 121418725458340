export const PUBLISH_TO_PUBLIC = 'PUBLISH_TO_PUBLIC'
export const PUBLISH_TO_PEERS = 'PUBLISH_TO_PEERS'
export const UPDATE_SHARING_SETTINGS = 'UPDATE_SHARING_SETTINGS'
export const UPDATE_SHARING_SETTINGS_SUCCESS = 'UPDATE_SHARING_SETTINGS_SUCCESS'
export const UPDATE_SHARING_SETTINGS_ERROR = 'UPDATE_SHARING_SETTINGS_ERROR'

export const GET_PUBLIC_PORTFOLIO = 'GET_PUBLIC_PORTFOLIO'
export const GET_PUBLIC_PORTFOLIO_SUCCESS = 'GET_PUBLIC_PORTFOLIO_SUCCESS'
export const GET_PUBLIC_PORTFOLIO_ERROR = 'GET_PUBLIC_PORTFOLIO_ERROR'
// modals
export const SET_VISIBILITY_MODAL = 'SET_VISIBILITY_MODAL'
export const SET_VISIBILITY_MODAL_SUCCESS = 'SET_VISIBILITY_MODAL_SUCCESS'
export const SET_VISIBILITY_MODAL_ERROR = 'SET_VISIBILITY_MODAL_ERROR'
export const SET_PUBLISH_MODAL = 'SET_PUBLISH_MODAL'
export const SET_PUBLISH_MODAL_SUCCESS = 'SET_PUBLISH_MODAL_SUCCESS'
export const SET_PUBLISH_MODAL_ERROR = 'SET_PUBLISH_MODAL_ERROR'

export const SET_VISIBILITY_MODAL_CONTENT = 'SET_VISIBILITY_MODAL_CONTENT'
export const SET_VISIBILITY_MODAL_CONTENT_SUCCESS =
  'SET_VISIBILITY_MODAL_CONTENT_SUCCESS'
export const SET_VISIBILITY_MODAL_CONTENT_ERROR =
  'SET_VISIBILITY_MODAL_CONTENT_ERROR'

export const SET_SHARE_MODAL = 'SET_SHARE_MODAL'
export const SET_SHARE_MODAL_SUCCESS = 'SET_SHARE_MODAL_SUCCESS'
export const SET_SHARE_MODAL_ERROR = 'SET_SHARE_MODAL_ERROR'

export const SHOW_EDIT_MY_STORY_MODAL = 'SHOW_EDIT_MY_STORY_MODAL'
export const HIDE_EDIT_MY_STORY_MODAL = 'HIDE_EDIT_MY_STORY_MODAL'

export const SHOW_ADD_MY_STORY_MODAL = 'SHOW_ADD_MY_STORY_MODAL'
export const HIDE_ADD_MY_STORY_MODAL = 'HIDE_ADD_MY_STORY_MODAL'

export const SHOW_EDIT_USER_BASIC_INFO_MODAL = 'SHOW_EDIT_USER_BASIC_INFO_MODAL'
export const HIDE_EDIT_USER_BASIC_INFO_MODAL = 'HIDE_EDIT_USER_BASIC_INFO_MODAL'

export const SHOW_ADD_USER_BASIC_INFO_MODAL = 'SHOW_ADD_USER_BASIC_INFO_MODAL'
export const HIDE_ADD_USER_BASIC_INFO_MODAL = 'HIDE_ADD_USER_BASIC_INFO_MODAL'

export const SHOW_EDIT_MENTOR_MODAL = 'SHOW_EDIT_MENTOR_MODAL'
export const HIDE_EDIT_MENTOR_MODAL = 'HIDE_EDIT_MENTOR_MODAL'

export const SHOW_ADD_MENTOR_MODAL = 'SHOW_ADD_MENTOR_MODAL'
export const HIDE_ADD_MENTOR_MODAL = 'HIDE_ADD_MENTOR_MODAL'

export const SHOW_EDIT_FAILURE_MODAL = 'SHOW_EDIT_FAILURE_MODAL'
export const HIDE_EDIT_FAILURE_MODAL = 'HIDE_EDIT_FAILURE_MODAL'

export const SHOW_ADD_FAILURE_MODAL = 'SHOW_ADD_FAILURE_MODAL'
export const HIDE_ADD_FAILURE_MODAL = 'HIDE_ADD_FAILURE_MODAL'

export const SHOW_EDIT_EDUCATION_MODAL = 'SHOW_EDIT_EDUCATION_MODAL'
export const HIDE_EDIT_EDUCATION_MODAL = 'HIDE_EDIT_EDUCATION_MODAL'

export const SHOW_ADD_EDUCATION_MODAL = 'SHOW_ADD_EDUCATION_MODAL'
export const HIDE_ADD_EDUCATION_MODAL = 'HIDE_ADD_EDUCATION_MODAL'

export const SHOW_EDIT_CREDENTIAL_MODAL = 'SHOW_EDIT_CREDENTIAL_MODAL'
export const HIDE_EDIT_CREDENTIAL_MODAL = 'HIDE_EDIT_CREDENTIAL_MODAL'

export const SHOW_ADD_CREDENTIAL_MODAL = 'SHOW_ADD_CREDENTIAL_MODAL'
export const HIDE_ADD_CREDENTIAL_MODAL = 'HIDE_ADD_CREDENTIAL_MODAL'

export const SHOW_EDIT_IMMERSION_MODAL = 'SHOW_EDIT_IMMERSION_MODAL'
export const HIDE_EDIT_IMMERSION_MODAL = 'HIDE_EDIT_IMMERSION_MODAL'

export const SHOW_ADD_IMMERSION_MODAL = 'SHOW_ADD_IMMERSION_MODAL'
export const HIDE_ADD_IMMERSION_MODAL = 'HIDE_ADD_IMMERSION_MODAL'

export const SHOW_EDIT_WORK_EXPERIENCE_MODAL = 'SHOW_EDIT_WORK_EXPERIENCE_MODAL'
export const HIDE_EDIT_WORK_EXPERIENCE_MODAL = 'HIDE_EDIT_WORK_EXPERIENCE_MODAL'

export const SHOW_ADD_WORK_EXPERIENCE_MODAL = 'SHOW_ADD_WORK_EXPERIENCE_MODAL'
export const HIDE_ADD_WORK_EXPERIENCE_MODAL = 'HIDE_ADD_WORK_EXPERIENCE_MODAL'

export const SHOW_EDIT_COMPETITIVENESS_MODAL = 'SHOW_EDIT_COMPETITIVENESS_MODAL'
export const HIDE_EDIT_COMPETITIVENESS_MODAL = 'HIDE_EDIT_COMPETITIVENESS_MODAL'

export const SHOW_ADD_COMPETITIVENESS_MODAL = 'SHOW_ADD_COMPETITIVENESS_MODAL'
export const HIDE_ADD_COMPETITIVENESS_MODAL = 'HIDE_ADD_COMPETITIVENESS_MODAL'

export const SET_SHARE_MODAL_CONTENT = 'SET_SHARE_MODAL_CONTENT'
export const SET_SHARE_MODAL_CONTENT_SUCCESS = 'SET_SHARE_MODAL_CONTENT_SUCCESS'
export const SET_SHARE_MODAL_CONTENT_ERROR = 'SET_SHARE_MODAL_CONTENT_ERROR'

export const GET_SHARING_SETTINGS = 'GET_SHARING_SETTINGS'
export const GET_SHARING_SETTINGS_SUCCESS = 'GET_SHARING_SETTINGS_SUCCESS'
export const GET_SHARING_SETTINGS_ERROR = 'GET_SHARING_SETTINGS_ERROR'
export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
export const CHANGE_VIEW_MODE_SUCCESS = 'CHANGE_VIEW_MODE_SUCCESS'
export const CHANGE_VIEW_MODE_ERROR = 'CHANGE_VIEW_MODE_ERROR'

export const CHANGE_ACTIVE_SECTION = 'CHANGE_ACTIVE_SECTION'
export const CHANGE_ACTIVE_SECTION_SUCCESS = 'CHANGE_ACTIVE_SECTION_SUCCESS'
export const CHANGE_ACTIVE_SECTION_ERROR = 'CHANGE_ACTIVE_SECTION_ERROR'

export const PUBLISH_PORTFOLIO_SECTION = 'PUBLISH_PORTFOLIO_SECTION'
export const PUBLISH_PORTFOLIO_SECTION_SUCCESS =
  'PUBLISH_PORTFOLIO_SECTION_SUCCESS'
export const PUBLISH_PORTFOLIO_SECTION_ERROR = 'PUBLISH_PORTFOLIO_SECTION_ERROR'

export const SHARE_PORTFOLIO_SECTION = 'SHARE_PORTFOLIO_SECTION'
export const SHARE_PORTFOLIO_SECTION_SUCCESS = 'SHARE_PORTFOLIO_SECTION_SUCCESS'
export const SHARE_PORTFOLIO_SECTION_ERROR = 'SHARE_PORTFOLIO_SECTION_ERROR'

// Constants for isEditMyRelationship
export const EDIT_MY_RELATIONSHIP = 'EDIT_MY_RELATIONSHIP'
export const EDIT_MY_RELATIONSHIP_SUCCESS = 'EDIT_MY_RELATIONSHIP_SUCCESS'
export const EDIT_MY_RELATIONSHIP_ERROR = 'EDIT_MY_RELATIONSHIP_ERROR'

// Constants for isEditMyFailures
export const EDIT_MY_FAILURES = 'EDIT_MY_FAILURES'
export const EDIT_MY_FAILURES_SUCCESS = 'EDIT_MY_FAILURES_SUCCESS'
export const EDIT_MY_FAILURES_ERROR = 'EDIT_MY_FAILURES_ERROR'

// Constants for isEditMyMentors
export const EDIT_MY_MENTORS = 'EDIT_MY_MENTORS'
export const EDIT_MY_MENTORS_SUCCESS = 'EDIT_MY_MENTORS_SUCCESS'
export const EDIT_MY_MENTORS_ERROR = 'EDIT_MY_MENTORS_ERROR'
export const EDIT_WHO_SECTION = 'EDIT_WHO_SECTION'
export const EDIT_WHO_SECTION_SUCCESS = 'EDIT_WHO_SECTION_SUCCESS'
export const EDIT_WHO_SECTION_ERROR = 'EDIT_WHO_SECTION_ERROR'

export const SAVE_WHO_SECTION = 'SAVE_WHO_SECTION'
export const SAVE_WHO_SECTION_SUCCESS = 'SAVE_WHO_SECTION_SUCCESS'
export const SAVE_WHO_SECTION_ERROR = 'SAVE_WHO_SECTION_ERROR'

export const SAVE_PERSONAL_BRAND_STORY = 'SAVE_PERSONAL_BRAND_STORY'
export const SAVE_PERSONAL_BRAND_STORY_SUCCESS =
  'SAVE_PERSONAL_BRAND_STORY_SUCCESS'
export const SAVE_PERSONAL_BRAND_STORY_ERROR = 'SAVE_PERSONAL_BRAND_STORY_ERROR'

export const GET_USER_STORY = 'GET_USER_STORY'
export const GET_USER_STORY_SUCCESS = 'GET_USER_STORY_SUCCESS'
export const GET_USER_STORY_ERROR = 'GET_USER_STORY_ERROR'

export const SAVE_USER_STORY = 'SAVE_USER_STORY'
export const SAVE_USER_STORY_SUCCESS = 'SAVE_USER_STORY_SUCCESS'
export const SAVE_USER_STORY_ERROR = 'SAVE_USER_STORY_ERROR'

export const TOGGLE_USER_STORY = 'TOGGLE_USER_STORY'
export const TOGGLE_USER_STORY_SUCCESS = 'TOGGLE_USER_STORY_SUCCESS'
export const TOGGLE_USER_STORY_ERROR = 'TOGGLE_USER_STORY_ERROR'

export const GET_BASIC_USER_INFO = 'GET_BASIC_USER_INFO'
export const GET_BASIC_USER_INFO_SUCCESS = 'GET_BASIC_USER_INFO_SUCCESS'
export const GET_BASIC_USER_INFO_ERROR = 'GET_BASIC_USER_INFO_ERROR'
export const SAVE_BASIC_USER_INFO = 'SAVE_BASIC_USER_INFO'
export const SAVE_BASIC_USER_INFO_SUCCESS = 'SAVE_BASIC_USER_INFO_SUCCESS'
export const SAVE_BASIC_USER_INFO_ERROR = 'SAVE_BASIC_USER_INFO_ERROR'

export const GET_MY_RELATIONSHIPS = 'GET_MY_RELATIONSHIPS'
export const GET_MY_RELATIONSHIPS_SUCCESS = 'GET_MY_RELATIONSHIPS_SUCCESS'
export const GET_MY_RELATIONSHIPS_ERROR = 'GET_MY_RELATIONSHIPS_ERROR'

export const SAVE_MY_RELATIONSHIPS = 'SAVE_MY_RELATIONSHIPS'
export const SAVE_MY_RELATIONSHIPS_SUCCESS = 'SAVE_MY_RELATIONSHIPS_SUCCESS'
export const SAVE_MY_RELATIONSHIPS_ERROR = 'SAVE_MY_RELATIONSHIPS_ERROR'

export const TOGGLE_MY_RELATIONSHIPS = 'TOGGLE_MY_RELATIONSHIPS'
export const TOGGLE_MY_RELATIONSHIPS_SUCCESS = 'TOGGLE_MY_RELATIONSHIPS_SUCCESS'
export const TOGGLE_MY_RELATIONSHIPS_ERROR = 'TOGGLE_MY_RELATIONSHIPS_ERROR'

export const GET_MY_FAILURES = 'GET_MY_FAILURES'
export const GET_MY_FAILURES_SUCCESS = 'GET_MY_FAILURES_SUCCESS'
export const GET_MY_FAILURES_ERROR = 'GET_MY_FAILURES_ERROR'

export const SAVE_MY_FAILURES = 'SAVE_MY_FAILURES'
export const SAVE_MY_FAILURES_SUCCESS = 'SAVE_MY_FAILURES_SUCCESS'
export const SAVE_MY_FAILURES_ERROR = 'SAVE_MY_FAILURES_ERROR'

export const TOGGLE_MY_FAILURE = 'TOGGLE_MY_FAILURE'
export const TOGGLE_MY_FAILURE_SUCCESS = 'TOGGLE_MY_FAILURE_SUCCESS'
export const TOGGLE_MY_FAILURE_ERROR = 'TOGGLE_MY_FAILURE_ERROR'
export const CREATE_MY_FAILURE = 'CREATE_MY_FAILURE'
export const CREATE_MY_FAILURE_SUCCESS = 'CREATE_MY_FAILURE_SUCCESS'
export const CREATE_MY_FAILURE_ERROR = 'CREATE_MY_FAILURE_ERROR'

export const UPDATE_MY_FAILURE = 'UPDATE_MY_FAILURE'
export const UPDATE_MY_FAILURE_SUCCESS = 'UPDATE_MY_FAILURE_SUCCESS'
export const UPDATE_MY_FAILURE_ERROR = 'UPDATE_MY_FAILURE_ERROR'

export const DELETE_MY_FAILURE = 'DELETE_MY_FAILURE'
export const DELETE_MY_FAILURE_SUCCESS = 'DELETE_MY_FAILURE_SUCCESS'
export const DELETE_MY_FAILURE_ERROR = 'DELETE_MY_FAILURE_ERROR'

export const DELETE_MY_FAILURE_IMAGE = 'DELETE_MY_FAILURE_IMAGE'
export const DELETE_MY_FAILURE_IMAGE_SUCCESS = 'DELETE_MY_FAILURE_IMAGE_SUCCESS'
export const DELETE_MY_FAILURE_IMAGE_ERROR = 'DELETE_MY_FAILURE_IMAGE_ERROR'

// MY MENTORS
export const GET_MY_MENTORS = 'GET_MY_MENTORS'
export const GET_MY_MENTORS_SUCCESS = 'GET_MY_MENTORS_SUCCESS'
export const GET_MY_MENTORS_ERROR = 'GET_MY_MENTORS_ERROR'

export const SAVE_MY_MENTORS = 'SAVE_MY_MENTORS'
export const SAVE_MY_MENTORS_SUCCESS = 'SAVE_MY_MENTORS_SUCCESS'
export const SAVE_MY_MENTORS_ERROR = 'SAVE_MY_MENTORS_ERROR'

export const CREATE_MY_MENTOR = 'CREATE_MY_MENTOR'
export const CREATE_MY_MENTOR_SUCCESS = 'CREATE_MY_MENTOR_SUCCESS'
export const CREATE_MY_MENTOR_ERROR = 'CREATE_MY_MENTOR_ERROR'

export const UPDATE_MY_MENTOR = 'UPDATE_MY_MENTOR'
export const UPDATE_MY_MENTOR_SUCCESS = 'UPDATE_MY_MENTOR_SUCCESS'
export const UPDATE_MY_MENTOR_ERROR = 'UPDATE_MY_MENTOR_ERROR'

export const DELETE_MY_MENTOR_IMAGE = 'DELETE_MY_MENTOR_IMAGE'
export const DELETE_MY_MENTOR_IMAGE_SUCCESS = 'DELETE_MY_MENTOR_IMAGE_SUCCESS'
export const DELETE_MY_MENTOR_IMAGE_ERROR = 'DELETE_MY_MENTOR_IMAGE_ERROR'

export const DELETE_MY_MENTOR = 'DELETE_MY_MENTOR'
export const DELETE_MY_MENTOR_SUCCESS = 'DELETE_MY_MENTOR_SUCCESS'
export const DELETE_MY_MENTOR_ERROR = 'DELETE_MY_MENTOR_ERROR'

export const TOGGLE_MY_MENTOR = 'TOGGLE_MY_MENTOR'
export const TOGGLE_MY_MENTOR_SUCCESS = 'TOGGLE_MY_MENTOR_SUCCESS'
export const TOGGLE_MY_MENTOR_ERROR = 'TOGGLE_MY_MENTOR_ERROR'

///////////// WHAT CAN I DO SECTION //////////////

export const GET_SKILLS = 'GET_SKILLS'
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS'
export const GET_SKILLS_ERROR = 'GET_SKILLS_ERROR'

export const GET_MY_PROJECTS = 'GET_MY_PROJECTS'
export const GET_MY_PROJECTS_SUCCESS = 'GET_MY_PROJECTS_SUCCESS'
export const GET_MY_PROJECTS_ERROR = 'GET_MY_PROJECTS_ERROR'

//////////// HOW DO I PROVE IT /////////////////

export const GET_MY_EDUCATIONS = 'GET_MY_EDUCATIONS'
export const GET_MY_EDUCATIONS_SUCCESS = 'GET_MY_EDUCATIONS_SUCCESS'
export const GET_MY_EDUCATIONS_ERROR = 'GET_MY_EDUCATIONS_ERROR'

export const UPDATE_MY_EDUCATION = 'UPDATE_MY_EDUCATION'
export const UPDATE_MY_EDUCATION_SUCCESS = 'UPDATE_MY_EDUCATION_SUCCESS'
export const UPDATE_MY_EDUCATION_ERROR = 'UPDATE_MY_EDUCATION_ERROR'

export const ADD_MY_EDUCATION = 'ADD_MY_EDUCATION'
export const ADD_MY_EDUCATION_SUCCESS = 'ADD_MY_EDUCATION_SUCCESS'
export const ADD_MY_EDUCATION_ERROR = 'ADD_MY_EDUCATION_ERROR'

export const DELETE_MY_EDUCATION = 'DELETE_MY_EDUCATION'
export const DELETE_MY_EDUCATION_SUCCESS = 'DELETE_MY_EDUCATION_SUCCESS'
export const DELETE_MY_EDUCATION_ERROR = 'DELETE_MY_EDUCATION_ERROR'

export const GET_MY_CREDENTIALS = 'GET_MY_CREDENTIALS'
export const GET_MY_CREDENTIALS_SUCCESS = 'GET_MY_CREDENTIALS_SUCCESS'
export const GET_MY_CREDENTIALS_ERROR = 'GET_MY_CREDENTIALS_ERROR'

export const UPDATE_MY_CREDENTIAL = 'UPDATE_MY_CREDENTIAL'
export const UPDATE_MY_CREDENTIAL_SUCCESS = 'UPDATE_MY_CREDENTIAL_SUCCESS'
export const UPDATE_MY_CREDENTIAL_ERROR = 'UPDATE_MY_CREDENTIAL_ERROR'

export const ADD_MY_CREDENTIAL = 'ADD_MY_CREDENTIAL'
export const ADD_MY_CREDENTIAL_SUCCESS = 'ADD_MY_CREDENTIAL_SUCCESS'
export const ADD_MY_CREDENTIAL_ERROR = 'ADD_MY_CREDENTIAL_ERROR'

export const DELETE_MY_CREDENTIAL = 'DELETE_MY_CREDENTIAL'
export const DELETE_MY_CREDENTIAL_SUCCESS = 'DELETE_MY_CREDENTIAL_SUCCESS'
export const DELETE_MY_CREDENTIAL_ERROR = 'DELETE_MY_CREDENTIAL_ERROR'

export const GET_MY_IMMERSIONS = 'GET_MY_IMMERSIONS'
export const GET_MY_IMMERSIONS_SUCCESS = 'GET_MY_IMMERSIONS_SUCCESS'
export const GET_MY_IMMERSIONS_ERROR = 'GET_MY_IMMERSIONS_ERROR'

export const UPDATE_MY_IMMERSION = 'UPDATE_MY_IMMERSION'
export const UPDATE_MY_IMMERSION_SUCCESS = 'UPDATE_MY_IMMERSION_SUCCESS'
export const UPDATE_MY_IMMERSION_ERROR = 'UPDATE_MY_IMMERSION_ERROR'

export const ADD_MY_IMMERSION = 'ADD_MY_IMMERSION'
export const ADD_MY_IMMERSION_SUCCESS = 'ADD_MY_IMMERSION_SUCCESS'
export const ADD_MY_IMMERSION_ERROR = 'ADD_MY_IMMERSION_ERROR'

export const DELETE_MY_IMMERSION = 'DELETE_MY_IMMERSION'
export const DELETE_MY_IMMERSION_SUCCESS = 'DELETE_MY_IMMERSION_SUCCESS'
export const DELETE_MY_IMMERSION_ERROR = 'DELETE_MY_IMMERSION_ERROR'

export const GET_MY_WORK_EXPERIENCES = 'GET_MY_WORK_EXPERIENCES'
export const GET_MY_WORK_EXPERIENCES_SUCCESS = 'GET_MY_WORK_EXPERIENCES_SUCCESS'
export const GET_MY_WORK_EXPERIENCES_ERROR = 'GET_MY_WORK_EXPERIENCES_ERROR'

export const UPDATE_MY_WORK_EXPERIENCE = 'UPDATE_MY_WORK_EXPERIENCE'
export const UPDATE_MY_WORK_EXPERIENCE_SUCCESS =
  'UPDATE_MY_WORK_EXPERIENCE_SUCCESS'
export const UPDATE_MY_WORK_EXPERIENCE_ERROR = 'UPDATE_MY_WORK_EXPERIENCE_ERROR'

export const ADD_MY_WORK_EXPERIENCE = 'ADD_MY_WORK_EXPERIENCE'
export const ADD_MY_WORK_EXPERIENCE_SUCCESS = 'ADD_MY_WORK_EXPERIENCE_SUCCESS'
export const ADD_MY_WORK_EXPERIENCE_ERROR = 'ADD_MY_WORK_EXPERIENCE_ERROR'

export const DELETE_MY_WORK_EXPERIENCE = 'DELETE_MY_WORK_EXPERIENCE'
export const DELETE_MY_WORK_EXPERIENCE_SUCCESS =
  'DELETE_MY_WORK_EXPERIENCE_SUCCESS'
export const DELETE_MY_WORK_EXPERIENCE_ERROR = 'DELETE_MY_WORK_EXPERIENCE_ERROR'

export const GET_MY_COMPETITIVENESS = 'GET_MY_COMPETITIVENESS'
export const GET_MY_COMPETITIVENESS_SUCCESS = 'GET_MY_COMPETITIVENESS_SUCCESS'
export const GET_MY_COMPETITIVENESS_ERROR = 'GET_MY_COMPETITIVENESS_ERROR'

export const UPDATE_MY_COMPETITIVENESS = 'UPDATE_MY_COMPETITIVENESS'
export const UPDATE_MY_COMPETITIVENESS_SUCCESS =
  'UPDATE_MY_COMPETITIVENESS_SUCCESS'
export const UPDATE_MY_COMPETITIVENESS_ERROR = 'UPDATE_MY_COMPETITIVENESS_ERROR'
export const DELETE_MY_COMPETITIVENESS_IMAGE = 'DELETE_MY_COMPETITIVENESS_IMAGE'
export const DELETE_MY_COMPETITIVENESS_IMAGE_SUCCESS =
  'DELETE_MY_COMPETITIVENESS_IMAGE_SUCCESS'
export const DELETE_MY_COMPETITIVENESS_IMAGE_ERROR =
  'DELETE_MY_COMPETITIVENESS_IMAGE_ERROR'

export const ADD_MY_COMPETITIVENESS = 'ADD_MY_COMPETITIVENESS'
export const ADD_MY_COMPETITIVENESS_SUCCESS = 'ADD_MY_COMPETITIVENESS_SUCCESS'
export const ADD_MY_COMPETITIVENESS_ERROR = 'ADD_MY_COMPETITIVENESS_ERROR'

export const DELETE_MY_COMPETITIVENESS = 'DELETE_MY_COMPETITIVENESS'
export const DELETE_MY_COMPETITIVENESS_SUCCESS =
  'DELETE_MY_COMPETITIVENESS_SUCCESS'
export const DELETE_MY_COMPETITIVENESS_ERROR = 'DELETE_MY_COMPETITIVENESS_ERROR'

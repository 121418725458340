const EnLangs = {
  'general.edit': 'Edit',
  'general.view_all': 'View All',
  'general.view_profile': 'View Profile >>',
  'general.share': 'Share',
  'general.go_previous': 'Previous',
  'general.go_next': 'Next',
  'general.save': 'Save',
  'general.saving': 'Saving...',
  'general.ready': 'Ready',
  'general.start': 'Start!',
  'general.click_here': 'Click here',
  'general.submit': 'Send',
  'general.login': 'Login',
  'general.copy': 'Copied to clipboard',
  'general.copy_1': 'Copy',
  'general.message_success': 'Your message has been successfully sent.',
  'general.browse': 'Browse',
  'general.readMore': 'Read more...',
  'general.verifyWithNova': 'Verify with Novae360',
  'general.loading': 'Loading...',

  'content.write_note': 'Write your note here',
  'content.my_notes': 'My Notes',
  'content.my_chat': 'My Chat',

  'navigation.dashboard': 'MY DASHBOARD',
  'navigation.course': 'MY COURSE',
  'navigation.journal': 'MY JOURNAL',
  'navigation.portfolio': 'MY PORTFOLIO',
  'old.navigation.startup_live': 'MY STARTUP LIVE™',
  'navigation.startup_live': 'MY STARTUP LIVE',
  'navigation.beyond_your_course': 'BEYOND LEARN TO START',
  'navigation.my_market_resources': 'MY MARKET RESOURCES',
  'navigation.create_your_account': 'Create your account',
  'navigation.learn_to_start': 'MY LEARN TO START',
  'navigation.lts_journal': 'MY LTS JOURNAL',
  'navigation.wellness_journal': 'MY WELLNESS JOURNAL',
  'navigation.market_ready': 'MY MARKET-READY JOURNAL',
  'navigation.personal_finance_journal': 'MY PERSONAL FINANCE JOURNAL',
  'navigation.lts_course_in_entrepreneurship': 'LTS COURSE IN ENTREPRENEURSHIP',
  'navigation.profile': 'Profile',
  'navigation.contact_us': 'Contact Support',
  'navigation.login': 'Login',
  'navigation.logout': 'Logout',

  //Login
  'login.email': 'Email address',
  'login.password': 'Password',
  'login.title': 'Login To Your Account',
  'login.title2': 'Login',
  'login.security': 'The security of your information is important.',
  'login.protect_data': 'Learn how we protect you.',
  'login.forgot_password': ' Forgot your password?',
  'login.register': 'Don’t have an account?',
  'login.register_link': 'Register',
  'login.next': 'Next',
  'login.forgotPasswordEmail': 'le.name@email.com',

  //LTS Secure
  'lts_secure.partnership_for': 'A partnership for',
  'lts_secure.security': 'Security',
  'lts_secure.security_paragraph':
    'The Learn to Start e-Learning Platform integrates the resources of Amazon Web Services and the security of Amazon Cognito to provide its participants with a powerful and secure online experience.',
  'lts_secure.keeping_your_login': 'Keeping your login',
  'lts_secure.safe_secure': 'Safe & Secure',
  'lts_secure.safe_secure_paragraph_1':
    'Amazon Cognito provides a secure login experience that controls and gives access to resources to authorized users.',
  'lts_secure.safe_secure_paragraph_2':
    'With adaptive authentication to help protect user accounts, Amazon Cognito detects unusual sign-in activity, such as sign-in attempts from new locations and devices, and either prompts users for additional verification or blocks the sign-in request. Users can verify their identities using SMS or a Time-based One-time Password (TOTP) generator, such as Google Authenticator.',
  'lts_secure.safe_secure_paragraph_3':
    'Amazon Cognito helps protect users from unauthorized access to their accounts using compromised credentials. When Amazon Cognito detects users have entered credentials that have been compromised elsewhere, it prompts them to change their password.',
  'lts_secure.globally_recognized': 'Globally recognized',
  'lts_secure.compliance_standards': 'Compliance standards',
  'lts_secure.compliance_standards_paragraph_1':
    'Amazon Cognito is a standards-based Identity Provider and supports identity and access management standards, such as Oauth 2.0, SAML 2.0, and OpenID Connect.',
  'lts_secure.compliance_standards_paragraph_2':
    'With Amazon Cognito, users can sign in through social identity providers such as Google, Facebook, and Amazon, and through enterprise identity providers such as Microsoft Active Directory via SAML.',
  'lts_secure.compliance_standards_paragraph_3':
    'Amazon Cognito supports multi-factor authentication and encryption of data-at-rest and in-transit. Amazon Cognito is HIPAA eligible and PCI DSS, SOC, ISO/IEC 27001, ISO/IEC 27017, ISO/IEC 27018, and ISO 9001 compliant.',

  //Forgot password
  'forgot_password.title': 'Forgot your password?',
  'forgot_password.input_your_email':
    'Input your email below, and if there is an account associated with it, you’ll be sent a link to reset your password.',
  'forgot_password.associated':
    'If there is an account associated with this email, you will receive an email with a link to reset your password.',
  'forgot_password.mistake': 'Got here by mistake?',

  //Reset passsword
  'reset_password.title': 'Reset Your Password',
  'reset_password.new_password': 'New Password',
  'reset_password.confirm_new_password': 'Confirm New Password',
  'reset_password.password_field_empty': 'Password filed is empty',
  'reset_password.password_not_match': "The password you type doesn't match",
  'reset_password.password_conform_policy':
    'Password must contain at least 8 numbers and/or characters and it should have at least one number, lowercase & uppercase character',

  //Create Account
  'create_account.title': 'Create an Account',
  'create_account.page_description':
    'Registration is exclusively for our school, university, college, and organization partners. Please input your email and unique community code below to continue.',
  'create_account.full_name': 'Full Name',
  'create_account.email': 'Email',
  'create_account.password': 'Password',
  'create_account.password_policy':
    '(Your password must contain at least 8 numbers and/or characters)',
  'create_account.confirm_password': 'Confirm Password',
  'create_account.community_code': 'Community Code',
  'create_account.terms':
    'By creating an account you agree to our Terms of Service and Privacy Policy',
  'create_account.security': 'The security of your information is important.',
  'create_account.security_link': 'Learn how we protect you.',
  'create_account.already_registered': 'Already registered?',

  //Terms
  'terms.agreement_title': 'Agreement between You and Startup Studio LLC',
  'terms.paragraph_1':
    'PLEASE READ THIS TERMS OF USE AGREEMENT (THE “TERMS”) CAREFULLY. BY ACCESSING OR USING THIS WEBSITE OR ANY OTHER WEBSITES OF STARTUP STUDIO LLC, (“Startup Studio LLC”) WITH LINKS TO THIS AGREEMENT (COLLECTIVELY, THE “WEBSITE”) IN ANY WAY, INCLUDING USING THE SERVICES ENABLED VIA THE WEBSITE (THE “SERVICES”) BY STARTUP STUDIO LLC OR USERS OF THE SITE (“USERS”), CLICKING THE “I ACCEPT” CHECK BOX, OR MERELY BROWSING THE WEBSITE, YOU REPRESENT THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR USE THIS WEBSITE OR THE SERVICES.',
  'terms.paragraph_2':
    'THESE TERMS INCLUDE A CLASS ACTION WAIVER AND WAIVER OF JURY TRIALS, AND REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES.',
  'terms.paragraph_3':
    'The Services include, but are not limited to, a learning management system that entities (“Entities”) may subscribe to and provide to their instructors and learners (“Instructors” and “Learners”) in connection with courses (each, a “Course”). Startup Studio LLC provides an account that enables a designated User to manage the Services, manage Learner and Instructor access to the Services, and provide general support on behalf of an Entity. “User” means any user of the Services, including Learners, and Instructors.',
  'terms.paragraph_4.1':
    '1. Use of the Services and Startup Studio LLC Properties. ',
  'terms.paragraph_4.2':
    'The Website, the Services, and the information and content made available (“Content”) by Startup Studio LLC on the Website and in the Services (collectively, the “Startup Studio LLC Properties”) are protected by copyright laws throughout the world. Subject to the Terms, Startup Studio LLC grants you a limited license to reproduce portions of Startup Studio LLC Properties for the sole purpose of using the Services for your personal purposes or, in the event you are an Instructor, your educational purposes.',
  'terms.paragraph_5.1': '1.1 Application License. ',
  'terms.paragraph_5.2':
    'Startup Studio LLC may also provide a mobile application (the “Application”) for use with the Services. Your use of the Application is governed by the End User License Agreement that accompanies the Application in the marketplace where you download it.',
  'terms.paragraph_6.1': '1.2 Certain Restrictions. ',
  'terms.paragraph_6.2':
    'The rights granted to you in the Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, reproduce, distribute, host or otherwise commercially exploit Startup Studio LLC LLC Properties, (b) you shall not use framing techniques to enclose any trademark, logo, or other Startup Studio LLC Properties; (c) you shall not use any metatags or other “hidden text” using Startup Studio LLC’s name or trademarks; (d) you shall not modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of Startup Studio LLC LLC Properties except to the extent the foregoing restrictions are expressly prohibited by applicable law; (e) you shall not use any manual or automated software, devices, or other processes (including but not limited to spiders, robots, scrapers, crawlers, avatars, data mining tools, or the like) to “scrape” or download data from any web pages contained in the Website; (f) except as expressly stated herein, no part of Startup Studio LLC LLC Properties may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and (g) you shall not remove or destroy any copyright notices or other proprietary markings contained on or in Startup Studio LLC LLC Properties. Any future release, update or other addition to Startup Studio LLC LLC Properties shall be subject to the Terms. Startup Studio LLC, its suppliers and service providers reserve all rights not granted in the Terms. Any unauthorized use of Startup Studio LLC LLC Properties terminates the licenses granted by Startup Studio LLC pursuant to the Terms.',
  'terms.paragraph_7.1': '2. Registration. ',
  'terms.paragraph_7.2':
    'In order to access certain features of Startup Studio LLC LLC Properties you must create an account (“Account”). Certain features are only available to Instructor Accounts.',
  'terms.paragraph_8.1': '2.1 Instructor Accounts. ',
  'terms.paragraph_8.2':
    'If you are an Instructor, Startup Studio LLC will provide you with access to an Instructor Account, provided that you (1) provide any necessary information about you and/or your school when registering the Account (“Registration Data”) and (2) accept the Terms.',
  'terms.paragraph_9.1': '2.2 Learner Accounts. ',
  'terms.paragraph_9.2':
    'To create a Learner Account, you must (1) be provided with access to an account invite code by the Entity, (2) provide us with any Registration Data requested in the registration and payment forms, and (3) accept the Terms.',
  'terms.paragraph_10.1': '2.3 Registration Data. ',
  'terms.paragraph_10.2':
    'In registering for the Services, you agree to (1) provide true, current, and complete Registration Data; and (2) maintain and promptly update the Registration Data to keep it true, current, and complete. You are responsible for all activities that occur under your Account. You may not share your Account or password with anyone, and you agree to notify Startup Studio LLC immediately of any unauthorized use of your password or any other breach of security. You agree not to create or access an Account using a false identity or information, or on behalf of someone other than yourself. You agree that you shall not have more than one Account at any given time. You agree not to create an Account or use Startup Studio LLC Properties if you have been previously removed by Startup Studio LLC, or if you have been previously banned from any of Startup Studio LLC Properties.',
  'terms.paragraph_11.1': '2.4 Social Networking Services. ',
  'terms.paragraph_11.2':
    'We may permit you to login to the Services with your login credentials from certain social networking sites (e.g., Facebook) (“SNS”). If you log in or otherwise associate your Account with your login credentials from such SNS, we may receive information about you from such SNS, in accordance with the terms and conditions (e.g., terms of use and privacy policy) of the SNS (“SNS Terms”). If you elect to share your information with these SNS, we will share information with them in accordance with your election. The SNS Terms of such SNS will apply to the information we disclose to them.',
  'terms.paragraph_12.1': '2.5 Necessary Equipment and Software. ',
  'terms.paragraph_12.2':
    'You must provide all equipment and software necessary to connect to Startup Studio LLC Properties, including but not limited to, a mobile device that is suitable to connect with and use the Application. You are solely responsible for any fees, including Internet connection or mobile fees, that you incur when accessing Startup Studio LLC Properties.',
  'terms.paragraph_13.1': '3. Payment Terms. ',
  'terms.paragraph_13.2':
    'All of the Courses offered as part of the Services are available to Learners in exchange for a fee. You may purchase such Courses by following the directions on the Site. If you purchase a Course, you agree to pay the then-current applicable Course fee listed on the Site (“Course Fees”) and Startup Studio LLC will bill the credit card or PayPal account you submit in purchasing the Course. All payments are refundable according to Startup Studio LLC’s standard refund policy, if any.. You hereby authorize Startup Studio LLC to bill your credit card or PayPal account, as applicable, for the Course Fees as described above. Course Fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you are responsible for payment of all such taxes, levies or duties. If any Course Fee cannot be charged to your credit card or PayPal account for any reason, Startup Studio LLC may provide you, via email, notice of such non-payment and a link for you to update your payment information. If such non-payment is not remedied within seven (7) days after receiving such notice of non-payment, Startup Studio LLC may terminate your access to the applicable Course',
  'terms.paragraph_14': '4. Responsibility for Content. ',
  'terms.paragraph_15.1': '4.1 Types of Content. ',
  'terms.paragraph_15.2':
    'You acknowledge that all Content, including Startup Studio LLC Properties, is the sole responsibility of the party from whom such Content originated. This means that you, and not Startup Studio LLC, are entirely responsible for all Content that you upload, post, e-mail, transmit or otherwise make available (“Make Available”) through Startup Studio LLC Properties (“Your Content”). Other Users of Startup Studio LLC Properties, and not Startup Studio LLC, are similarly responsible for all Content such other Users Make Available through Startup Studio LLC Properties (“User Content”).',
  'terms.paragraph_16.1': '4.2 No Obligation to Pre-Screen Content. ',
  'terms.paragraph_16.2':
    'You acknowledge that Startup Studio LLC has no obligation to pre-screen Content (including, but not limited to, Your Content and User Content), although Startup Studio LLC reserves the right in its sole discretion to pre-screen, refuse, or remove any Content that (1) violates any law or regulation, (2) violates these Terms, including the User Code of Conduct set forth in Section 5.4 hereof, and/or (3) otherwise creates liability for Startup Studio LLC.',
  'terms.paragraph_17': '5. Ownership.',
  'terms.paragraph_18.1': '5.1 Startup Studio LLC Properties. ',
  'terms.paragraph_18.2':
    'Except with respect to Your Content and User Content, you agree that Startup Studio LLC and its suppliers own all rights, title and interest in Startup Studio LLC Properties. Startup Studio LLC’s name and other related graphics, logos, service marks and trade names used on or in connection with Startup Studio LLC Properties are the trademarks of Startup Studio LLC and may not be used without permission in connection with any third-party products or services. Other trademarks, service marks and trade names that may appear on or in Startup Studio LLC Properties are the property of their respective owners.',
  'terms.paragraph_19.1': '5.2 Your Content. ',
  'terms.paragraph_19.2':
    'Your Content shall at all times remain your sole property and you agree that you have no right, title or interest in or to any other Content that appears on or in Startup Studio LLC Properties. You represent that you have all necessary right, power and authority to post Your Content to Startup Studio LLC Properties. You agree to allow Startup Studio LLC and its applicable contractors to freely host, reproduce, transmit, modify, display and otherwise use Your Content (in whole or in part) as reasonably necessary to provide the Services to you, and in accordance with Startup Studio LLC’s agreement with your Entity, if applicable.',
  'terms.paragraph_20.1': '5.3 Your Account. ',
  'terms.paragraph_20.2':
    'Notwithstanding anything contained herein to the contrary, by submitting Your Content to any forums, comments or any other area on or in Startup Studio LLC Properties, you hereby expressly permit Startup Studio LLC to identify you by your username as the contributor of Your Content in any publication in any form, media, or technology now known or later developed in connection with Your Content.',
  'terms.paragraph_21.1': '5.4 User Code of Conduct. ',
  'terms.paragraph_21.2':
    'As a condition of use, you agree not to use Startup Studio LLC Properties for any purpose that is prohibited by the Terms or by applicable law. Do not post, or permit others to post, content on Startup Studio LLC Properties or on your profile that (i) encourages illegal activities, is fraudulent, or is unlawful; (ii) insults, defames, harasses, or threatens others; (iii) violates the copyright or intellectual property or privacy rights of others; (iv) contains obscene, vulgar, pornographic, or libelous material; (v) harms or impersonates others, including other Users; or (vi) advertises or sells a product or service. Do not reproduce content from your Course or other Learners unless allowed by the express copyright terms laid out by the Instructor (e.g. Creative Commons). Do not share the solutions to assignments with others unless this is expressly authorized by the Instructor. Do not submit the work of others as your own work. Respect the privacy of other Users. Respect the diversity of opinions and cultures that will be presented by other Users. Do not attempt or engage in, any potentially harmful acts that are directed against Startup Studio LLC Properties, including but not limited to violating or attempting to violate any security features of Startup Studio LLC Properties, introducing viruses, worms, or similar harmful code into Startup Studio LLC LC Properties, or interfering or attempting to interfere with use of Startup Studio LLC Properties by any other user, host or network, including by means of overloading, “flooding,” “spamming,” “mail bombing,” or “crashing” Startup Studio LLC Properties. If you believe that someone has violated this code of conduct, begin by notifying the Instructor of the Course. If the issue is not addressed by the Instructor to your satisfaction, contact help@joinstartupstudio.com with your concerns.',
  'terms.paragraph_22.1': '5.5 Feedback. ',
  'terms.paragraph_22.2':
    'You agree that submission of any ideas, suggestions, documents, and/or proposals to Startup Studio LLC through its suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at your own risk and that Startup Studio LLC has no obligations (including without limitation obligations of confidentiality) with respect to such Feedback. You represent and warrant that you have all rights necessary to submit the Feedback. You hereby grant to Startup Studio LLC a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right and license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in any manner, any and all Feedback, and to sublicense the foregoing rights, in connection with the operation and maintenance of Startup Studio LLC Properties.',
  'terms.paragraph_23.1': '6. Investigations. ',
  'terms.paragraph_23.2':
    'Startup Studio LLC may, but is not obligated to, monitor or review Startup Studio LLC Properties and Content at any time. Without limiting the foregoing, Startup Studio LLC shall have the right, in its sole discretion, to remove any of Your Content for any reason (or no reason), including if such Content violates the Terms or any applicable law. Although Startup Studio LLC does not generally monitor user activity occurring in connection with Startup Studio LLC Properties, if Startup Studio LLC becomes aware of any possible violations by you of any provision of the Terms, Startup Studio LLC reserves the right to investigate such violations, and Startup Studio LLC may, at its sole discretion, terminate your license to use Startup Studio LLC Properties, or change, alter or remove Your Content, in whole or in part.',
  'terms.paragraph_24': '7. Interactions with Other Users.',
  'terms.paragraph_25.1': '7.1 User Responsibility. ',
  'terms.paragraph_25.2':
    'You are solely responsible for your interactions with other Users of the Services and any other parties with whom you interact through the Services; provided, however, that Startup Studio LLC reserves the right, but has no obligation, to intercede in disputes among Users. You agree that Startup Studio LLC will not be responsible for any liabilities incurred as the result of such interaction.',
  'terms.paragraph_26.1': '7.2 Content Provided by Other Users. ',
  'terms.paragraph_26.2':
    'Startup Studio LLC Properties may contain User Content provided by other Users. Startup Studio LLC is not responsible for and does not control User Content. Startup Studio LLC has no obligation to review or monitor, and does not approve, endorse or make any representations or warranties with respect to User Content. You use all User Content and interact with other Users at your own risk.',
  'terms.paragraph_27': '8. Third-Party Websites.',
  'terms.paragraph_28.1': '8.1 Third-Party Websites. ',
  'terms.paragraph_28.2':
    'Startup Studio LLC Properties may contain links to third-party websites (“Third-Party Websites”). When you click on a link to a Third-Party Website, we will not warn you that you have left Startup Studio LLC Properties and are subject to separate terms and conditions or privacy policies. Such Third-Party Websites are not under the control of Startup Studio LLC. Startup Studio LLC is not responsible for any Third-Party Websites and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Websites, or their products or services. You use all links in Third-Party Websites at your own risk. You should review applicable terms and policies, including privacy and data gathering practices, of any Third-Party Websites, and should make whatever investigation you feel necessary or appropriate before proceeding with any transaction with any third-party.',
  'terms.paragraph_29.1': '8.2 App Stores. ',
  'terms.paragraph_29.2':
    'You acknowledge and agree that the availability of the Application and the Services is dependent on the third-party from which you received the Application, e.g., the Apple or Android app stores.',
  'terms.paragraph_30.1': '9. Indemnification. ',
  'terms.paragraph_30.2':
    'You agree to indemnify, defend, and hold Startup Studio LLC, its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (collectively the “Startup Studio LLC Parties”) harmless from any losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of: (a) Your Content; (b) your use of, or inability to use, Startup Studio LLC LLC Properties; (c) your violation of the Terms; (d) your violation of any rights of another party, including any User; or (e) your violation of any applicable laws, rules or regulations. Startup Studio LLC reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Startup Studio LLC in asserting any available defenses. You agree that the provisions in this section will survive any termination of your Account, the Terms, or your access to Startup Studio LLC Properties.',
  'terms.paragraph_31.1': '10. Disclaimer of Warranties. ',
  'terms.paragraph_31.2':
    'YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF STARTUP STUDIO LLC PROPERTIES IS AT YOUR SOLE RISK, AND STARTUP STUDIO LLC PROPERTIES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. STARTUP STUDIO LLC PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. STARTUP STUDIO LLC PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT: (1) STARTUP STUDIO LLC PROPERTIES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF STARTUP STUDIO LLC PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF STARTUP STUDIO LLC PROPERTIES WILL BE ACCURATE OR RELIABLE; OR (4) ANY ERRORS IN STARTUP STUDIO LLC PROPERTIES WILL BE CORRECTED. ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH STARTUP STUDIO LLC PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR PERSON, INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS STARTUP STUDIO LLC PROPERTIES, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT. THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS. STARTUP STUDIO LLC MAKES NO WARRANTY, REPRESENTATION OR CONDITION WITH RESPECT TO THE SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF THE SERVICES. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM STARTUP STUDIO LLC OR THROUGH STARTUP STUDIO LLC PROPERTIES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.',
  'terms.paragraph_32': '11. Limitation of Liability.',
  'terms.paragraph_33.1': '11.1 Disclaimer of Certain Damages. ',
  'terms.paragraph_33.2':
    'YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL STARTUP STUDIO LLC PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH STARTUP STUDIO LLC PROPERTIES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT STARTUP STUDIO LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THE TERMS, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF STARTUP STUDIO LLC PROPERTIES, ON ANY THEORY OF LIABILITY, RESULTING FROM: (1) THE USE OR INABILITY TO USE STARTUP STUDIO LLC PROPERTIES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED FOR TRANSACTIONS ENTERED INTO THROUGH STARTUP STUDIO LLC PROPERTIES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD-PARTY ON STARTUP STUDIO LLC PROPERTIES; OR (5) ANY OTHER MATTER RELATED TO STARTUP STUDIO LLC PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.',
  'terms.paragraph_34.1': '11.2 Cap on Liability. ',
  'terms.paragraph_34.2':
    'UNDER NO CIRCUMSTANCES WILL STARTUP STUDIO LLC PARTIES BE LIABLE TO YOU FOR MORE THAN FIFTY DOLLARS ($50 USD).',
  'terms.paragraph_35.1': '11.3 User Content. ',
  'terms.paragraph_35.2':
    'STARTUP STUDIO LLC PARTIES ASSUME NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED TO, YOUR CONTENT AND USER CONTENT), USER COMMUNICATIONS OR PERSONALIZATION SETTINGS.',
  'terms.paragraph_36.1':
    '11.4 No Liability for Conduct of Third Parties or Other Users. ',
  'terms.paragraph_36.2':
    'YOU ACKNOWLEDGE AND AGREE THAT STARTUP STUDIO LLC PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD STARTUP STUDIO LLC PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF STARTUP STUDIO LLC PROPERTIES. YOU UNDERSTAND THAT STARTUP STUDIO LLC DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF STARTUP STUDIO LLC PROPERTIES.',
  'terms.paragraph_37.1': '11.5 Basis of the Bargain. ',
  'terms.paragraph_37.2':
    'THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN STARTUP STUDIO LLC AND YOU.',
  'terms.paragraph_38.1':
    '12. Procedure for Making Claims of Copyright Infringement. ',
  'terms.paragraph_38.2':
    'It is Startup Studio LLC’s policy to terminate membership privileges of any User who repeatedly infringes copyright upon prompt notification to Startup Studio LLC by the copyright owner or the copyright owner’s legal agent. Without limiting the foregoing, if you believe that your work has been copied and posted on Startup Studio LLC Properties in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (1) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (2) a description of the copyrighted work that you claim has been infringed; (3) a description of the location on Startup Studio LLC Properties of the material that you claim is infringing; (4) your address, telephone number and e-mail address; (5) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; (6) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. Contact information for Startup Studio LLC’s Copyright Agent for notice of claims of copyright infringement is as follows: Beusse Wolter Sanks & Maire PLLC, 390 North Orange Ave. Suite 2500, Orlando, FL 32801; legal@joinstartupstudio.com.',
  'terms.paragraph_39': '13. Term and Termination.',
  'terms.paragraph_40.1': '13.1 Term. ',
  'terms.paragraph_40.2':
    'The Terms commence on the date when you accept them (as described in the preamble above) and remain in full force and effect while you use Startup Studio LLC Properties, unless terminated earlier in accordance with the Terms.',
  'terms.paragraph_41.1':
    '13.2 Termination of Services by Startup Studio LLC. ',
  'terms.paragraph_41.2':
    'If you have breached any provision of the Terms, or if Startup Studio LLC is required to do so by law (e.g., where the provision of the Website, the Application, or the Services is, or becomes, unlawful), Startup Studio LLC has the right to suspend or terminate any Services provided to you or to delete any of Your Content. You agree that all terminations for cause shall be made in Startup Studio LLC’s sole discretion and that Startup Studio LLC shall not be liable to you or any third-party for any termination of your Account. Termination of any Service includes removal of access to such Service and barring of further use of the Service. Termination of all Services also may include deletion of your password and all related information, files and Content associated with or inside your Account (or any part thereof), including Your Content. Upon termination of any Service, your right to use such Service will automatically terminate immediately. Startup Studio LLC will not have any liability whatsoever to you for any suspension or termination, including for deletion of Your Content. All provisions of the Terms which by their nature should survive, shall survive termination of Services, including without limitation, ownership provisions, warranty disclaimers, and limitation of liability.',
  'terms.paragraph_42': '14. General Provisions.',
  'terms.paragraph_43.1': '14.1 Electronic Communications. ',
  'terms.paragraph_43.2':
    'The communications between you and Startup Studio LLC use electronic means, whether you visit Startup Studio LLC Properties or send Startup Studio LLC e-mails, or whether Startup Studio LLC posts notices on Startup Studio LLC Properties or communicates with you via email. For contractual purposes, you (1) consent to receive communications from Startup Studio LLC in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Startup Studio LLC provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing. The foregoing does not affect any statutory rights you may have.',
  'terms.paragraph_44.1': '14.2 Release. ',
  'terms.paragraph_44.2':
    'You hereby release Startup Studio LLC Parties and their successors from claims, demands, any and all losses, damages, rights, and actions of any kind, including personal injuries, death, and property damage, that is either directly or indirectly related to or arises from any interactions with or conduct of other Website Users or third-party websites of any kind arising in connection with or as a result of the Terms or your use of Startup Studio LLC Properties. If you are a California resident, you hereby waive California Civil Code Section 1542, which states, “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.”',
  'terms.paragraph_45.1': '14.3 Assignment. ',
  'terms.paragraph_45.2':
    'The Terms, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Startup Studio LLC’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. Startup Studio LLC may assign its rights and obligations under these Terms to a third party without your consent.',
  'terms.paragraph_46.1': '14.4 Force Majeure. ',
  'terms.paragraph_46.2':
    'Startup Studio LLC shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials.',
  'terms.paragraph_47.1': '14.5 Compliance. ',
  'terms.paragraph_47.2':
    'If you believe that Startup Studio LLC has not adhered to the Terms, please contact Startup Studio LLC by emailing us at legal@joinstartupstudio.com. We will do our best to address your concerns. If you feel that your complaint has been addressed incompletely, we invite you to let us know for further investigation.',
  'terms.paragraph_48':
    '14.6 Arbitration Agreement; Class Waiver; Waiver of Trial by Jury.',
  'terms.paragraph_49':
    'Please read this Arbitration Agreement carefully. It is part of your contract with Startup Studio LLC and affects your rights. It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.',
  'terms.paragraph_50':
    '(a) Applicability of Arbitration Agreement. All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with the Terms or the use of any product or service provided by Startup Studio LLC that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement. This Arbitration Agreement applies to you and Startup Studio LLC, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.',
  'terms.paragraph_51':
    '(b) Notice Requirement and Informal Dispute Resolution. Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief. A Notice to Startup Studio LLC should be sent to: Attn: Shuffield Lowman, 1000 Legion Place, Suite 1700, Orlando, FL 32801. After the Notice is received, you and Startup Studio LLC may attempt to resolve the claim or dispute informally. If you and Startup Studio LLC do not resolve the claim or dispute within 30 days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.',
  'terms.paragraph_52':
    '(c) Arbitration Rules. Arbitration shall be initiated through the American Arbitration Association (“AAA”), an established alternative dispute resolution provider (“ADR Provider”) that offers arbitration as set forth in this section. If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider. The rules of the ADR Provider shall govern all aspects of this arbitration, including but not limited to the method of initiating and/or demanding arbitration, except to the extent such rules are in conflict with the Terms. The AAA Consumer Arbitration Rules governing the arbitration are available online at www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration shall be conducted by a single, neutral arbitrator. Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars ($10,000.00 USD) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief. For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars ($10,000.00 USD) or more, the right to a hearing will be determined by the Arbitration Rules. Any hearing will be held in a location within 100 miles of your residence, unless you reside outside of the United States, and unless the parties agree otherwise. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration, and shall pay an equal share of the fees and costs of the ADR Provider.',
  'terms.paragraph_53':
    '(d) Additional Rules for Non-appearance Based Arbitration: If non-appearance arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration. The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties.',
  'terms.paragraph_54':
    '(e) Time Limits. If you or Startup Studio LLC pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.',
  'terms.paragraph_55':
    '(f) Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and Startup Studio LLC, and the dispute will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms. The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and Startup Studio LLC.',
  'terms.paragraph_56':
    '(g) Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement. Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in court and are subject to very limited review by a court. In the event any litigation should arise between you and STARTUP STUDIO LLC in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND STARTUP STUDIO LLC WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.',
  'terms.paragraph_57':
    '(h) Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.',
  'terms.paragraph_58':
    '(i) Confidentiality. All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential. The parties agree to maintain confidentiality unless otherwise required by law. This Paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.',
  'terms.paragraph_59':
    '(j) Severability. If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.',
  'terms.paragraph_60':
    '(k) Right to Waive. Any or all of the rights and limitations set forth in this Agreement may be waived by the party against whom the claim is asserted. Such waiver shall not waive or effect any other portion of this Agreement.',
  'terms.paragraph_61':
    '(l) Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with Startup Studio LLC.',
  'terms.paragraph_62':
    '(m) Small Claims Court. Notwithstanding the foregoing, either you or Startup Studio LLC may bring an individual action in small claims court.',
  'terms.paragraph_63':
    '(n) Emergency Equitable Relief. Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.',
  'terms.paragraph_64':
    '(o) Claims Not Subject To Arbitration. Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, trademark, or trade secret shall not be subject to this arbitration agreement.',
  'terms.paragraph_65':
    '(p) Courts. In any circumstances where the foregoing Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Salt Lake County, Orange County Florida, for such purpose.',
  'terms.paragraph_66.1': '14.7 Governing Law. ',
  'terms.paragraph_66.2':
    'The Terms and any action related thereto will be governed and interpreted by and under the laws of the State of Florida, consistent with the Federal Arbitration Act, without giving effect to any conflicts of law principles that provide for the application of the law of another jurisdiction. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.',
  'terms.paragraph_67.1': '14.8 Notice. ',
  'terms.paragraph_67.2':
    'Where Startup Studio LLC requires that you provide an email address to access certain features of Startup Studio LLC LLC Properties, you are responsible for providing Startup Studio LLC with your most current email address. In the event that the last e-mail address you provided to Startup Studio LLC is not valid, or for any reason is not capable of delivering to you any notices required/ permitted by the Terms, Startup Studio LLC’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice. You may give notice to Startup Studio LLC at the following address: Attn: Shuffield Lowman, 1000 Legion Place, Suite 1700, Orlando, FL 32801. Such notice shall be deemed given when received by Startup Studio LLC by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail at the above address.',
  'terms.paragraph_68.1': '14.9 Waiver. ',
  'terms.paragraph_68.2':
    'Any waiver or failure by Startup Studio LLC to enforce any provision of the Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.',
  'terms.paragraph_69.1': '14.10 Severability. ',
  'terms.paragraph_69.2':
    'If any provision of the Terms is, for any reason, held to be invalid or unenforceable, the other provisions of the Terms will remain enforceable, and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.',
  'terms.paragraph_70.1': '14.11 Entire Agreement. ',
  'terms.paragraph_70.2':
    'The Terms are the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter.',

  //Register
  'register.get_ready_to_embark': 'Get ready to embark on a',
  'register.journey': 'journey',
  'register.unlike_any_other':
    'unlike any other where you will discover yourself and your passions, practice the art of failing, and uncover the world of entrepreneurship.',
  'register.welcome_to': 'Welcome to',
  'register.what_is': 'What is',
  'register.learn_to_start': 'Learn to start?',
  'register.learn_to_start_p1':
    'Learn to Start® (LTS) is a new model that has been invented as a solution for transforming learning. The Startup Studio has worked tirelessly since 2014, deep-diving and researching education and has brought this disruptive model to life through a very new curriculum merged with a state-of-the-art technology platform.',
  'register.learn_to_start_p2':
    'Today our Learn to Start model serves to deliver a completely new idea in how people learn. Learn to Start is NOT another siloed subject claiming to be innovative. Instead, Learn to Start is a life-long model of education built to develop the complete human being so that they can experience the outcomes of empowerment, performance, and wellness. It is a learning platform for life.',
  'register.powerful': 'A powerful',
  'register.platform': 'PLATFORM',
  'register.my_startup_live': 'MY STARTUP LIVE ™',
  'register.my_startup_live_text':
    'LTS participants gain access to our streaming platform, StartUp Live ™, where they engage with our expert instructors as they are introduced to the Learn to Start model.',
  'register.my_portfolio': 'MY PORTFOLIO',

  'register.my_portfolio_text':
    'Every LTS participant creates a Learn to Start portfolio inside of the Learn to Start e-learning platform that features their outcomes of empowerment, performance, and wellness. The portfolio can be used to promote every LTS participant’s personal brand as well as allowing each participant to gain feedback from multiple sources as part of engaging in the program.',
  'register.beyond_learn_to_start':
    'LTS participants gain access to powerful content, including encouragement and guidance from The Startup Studio’s CEO and founder, who created this series to offer his experiences and expertise. They also gain access to powerful master classes from our exclusive community of Global Ambassadors ™.',
  'register.spotlight': 'SPOTLIGHT®',
  'portfolio.my_portfolio_edit':
    'Share your empowerment, wellness, and performance with the global community',
  'register.spotlight_text':
    'LTS participants gain access to our pitch platform to apply for funding and mentorship from our LTS Alliance.',
  'register.coming_spring': '- COMING SPRING 2022 -',
  'register.spotlight_text_1':
    'LTS participants gain access to network building within our global community.',
  'register.my_marketplace': 'MY MARKETPLACE',
  'register.my_marketplace_text':
    'LTS participants offer their services to other LTS participants within the community.',
  'register.story_in_motion': 'STORY IN MOTION®',
  'register.story_in_motion_text':
    'LTS Global Ambassadors ™ share their professional and personal journeys as a guide to all who tune in.',
  'register.outcomes': 'OUTCOMES',
  'register.outcomes_text': 'OF THE LEARN TO START ENVIRONMENT',
  'register.outcomes_text1':
    'People who are confident and who know themselves.',
  'register.outcomes_text2':
    'People who understand what performance really is - sustainability, profitability, and efficiency.',
  'register.outcomes_text3':
    'People who can operate in remote teams with a high degree of independence and self-management skills.',
  'register.outcomes_text4':
    'People who can know how to solve problems - fast failure is imperative.',
  'register.outcomes_text5':
    'People who are creative and willing to take risk.',
  'register.outcomes_text6':
    'People who are excellent communicators that can quickly articulate progress, problems, and solutions.',
  'register.outcomes_text7':
    'People who know the difference between problem-solving and solving problems worth solving.',
  'register.outcomes_text8':
    'People who can work in teams and can work towards common objectives effectively.',
  'register.outcomes_text9':
    'People with high degrees of free agency and independence.',
  'register.outcomes_text10':
    'People who know and understand that relationships and networking are the keys to success.',
  'register.outcomes_text11':
    'People who are self-learners and who constantly practice self-development.',
  'register.outcomes_text12':
    'People who know how to brand solutions and who understand empathy.',
  'register.outcomes_text13':
    'People who know how to constantly analyze data and apply data to build meaningful solutions.',
  'register.are_you': 'Are you',
  'register.ready_to_start': 'Ready to start?',
  'register.who_should_join_this_platform': 'Who should join this platform?',
  'register.who_should_join_this_platform_text':
    'This platform is recommended for everyone. It is built to support participants as they learn what it means to authentically entrepreneur and create independent and powerful mindsets regardless of their area of study or their profession. You are going to learn directly from highly qualified successful American entrepreneurs.',
  'register.how_much_does_this_platform_cost':
    'How much does access to this platform cost?',
  'register.how_much_does_this_platform_cost_text':
    'To maintain access to your ongoing StartupLive classes and all the additional resources available, each month your card will be charged $15. We make it easy by automatically creating a subscription for you. You may cancel at any time.',
  'register.cancel_my_subscription':
    'What happens if I want to cancel my subscription?',
  'register.cancel_my_subscription_text':
    'You can cancel your subscription at any time by going to the My Account page inside your course and choosing the option to cancel.',
  'register.monthly_payment': '$15 per month',
  'register.cardholder_name': 'Cardholder name',
  'register.coupon_code': 'Coupon Code',
  'register.subscribe_now': 'Subscribe now',

  //Dashboard
  'dashboard.page_description': 'Welcome to Your Dashboard',
  'dashboard.my_community': 'My Community',
  'dashboard.my_notifications': 'My Notifications',
  'dashboard.connection_requests': 'Connection Requests',
  'dashboard.level_1': 'Level 1',
  'dashboard.level_2': 'Level 2',
  'dashboard.level_3': 'Level 3',
  'dashboard.view_all_connections': 'VIEW ALL CONNECTIONS',
  'dashboard.recommended_connections': 'Recommended Connections',
  'dashboard.upcoming_events': 'UPCOMING LIVE EVENTS',

  //Verify email
  'user.verify_email':
    'Please check your email to verify your account (if you don’t see it, please check your SPAM). If you didn’t get the email, ',
  'user.click_to_verify_email': 'click here ',
  'user.resend_verification_email': 'to resend the email.',
  'user.sending_verification_email': 'Sending email...',
  'user.verification_email_sent':
    'Verification email has been sent, please check your email.',

  //Journal
  'journal.write_response': 'Write your response here',
  'journal.word': 'word',
  'journal.words': 'words',
  'journal.minimum_words': 'words minimum',
  'journal.put_iam_video_link':
    'Put the link to your Personal Brand Video here',
  'journal.put_brand_introductory_video_link':
    'Put the link to your brand introductory video here...',
  'journal.view_document': 'View Document',
  'journal.files_uploaded_successfully':
    'Your file has been uploaded successfully',
  'journal.prev_lesson': `Go to Previous Lesson`,
  'journal.go_to_next_lesson': 'Go to Next Lesson',
  'journal.view_image': 'Click to view larger',
  'journal.download_pdf': 'Download PDF',
  'journal.download_jpg': 'Download JPG',
  'journal.get_your_certificate': 'Get your certificate',
  'journal.print_certificate': 'Print Certificate',

  //Portfolio
  'portfolio.edit_page_Summary_first': 'Summary ',
  'portfolio.edit_page_Summary_second': '(links to your profile) ',
  'portfolio.page_description':
    'Share your empowerment, performance, and wellness with the global community',
  'portfolio.preview': 'Preview',
  'portfolio.user_bio':
    'John Smith began his career by working for several companies, growing his skills in UI design. As he developed his abilities, he discovered he wanted to have a greater impact on his community and began looking for opportunities that would help him feel more empowered, build a powerful business, and create greater wellness by understanding how he could have a greater impact on the world around him. Upon discovering the Learn to Start Program in Entrepreneurship, he knew he had found exactly what he had been looking for.',
  'portfolio.this_is': 'THIS IS',
  'portfolio.empowerement': 'EMPOWERMENT',
  'portfolio.performance': 'PERFORMANCE',
  'portfolio.wellness': 'WELLNESS',
  'portfolio.empowerment_p1':
    'Knowing yourself is the key to building empowered human beings. That is why at The Startup Studio it all begins with story. Once people discover themselves, they can begin building their true potential and uncover how to properly tell their real story in the world.',
  'portfolio.empowerment_p2':
    'Your Personal Brand Video speaks to the outcome of EMPOWERMENT experienced by you as you tell your story and uncover your potential.',
  'portfolio.performance_p':
    'The Startup Studio challenges your to answer the question – What can you do? Because that is the only metric that will matter to a 21st century marketplace employer. In this program, you accomplish the outcome of PERFORMANCE by identifying a problem worth solving and proposing a solution that becomes the centerpiece of your startup. Throughout the process, your startup will begin to craft a powerful story about your unique solution and the benefits you offer to the greater global community.',
  'portfolio.wellness_p1':
    'People are at the heart of this program. Regardless of age, our purpose is to create people who know their story and are living into it in powerful ways. We call this outcome "WELLNESS".',
  'portfolio.wellness_p2':
    'As you understand yourself and how you fit in the world, you come into a state of wellness that empowers you to do incredible things.',
  'portfolio.wellness_p3':
    'The pitch deck you build for your startup proves exactly what you can do in the world. It is an incredible way to prove the value you and your startup’s story bring to the market.',
  'portfolio.publish_portfolio': 'Publish Portfolio',
  'portfolio.set_up_my_portfolio': 'Set Up My Portfolio',
  'portfolio.instructions': 'Instructions:',
  'portfolio.edit_portfolio_txt1':
    'Build your portfolio by entering the information below. If you need to update your name, profession, bio, headshot, or social media links, please visit your',
  'portfolio.visit_profile': 'Profile',
  'portfolio.edit_portfolio_txt1_1': 'page.',
  'portfolio.edit_portfolio_txt2':
    'As you progress through the course, you will be given additional information and instructions about how to create the various elements. ',
  'portfolio.edit_portfolio_txt3':
    'Once all elements are complete, you will be able to publish your portfolio, making it public and giving you the ability to share it with others outside the course.',
  'portfolio.learn_to_start': 'MY LEARN TO START PORTFOLIO',
  'portfolio.choose_your_header_color': 'Choose Header Color',
  'portfolio.i_am_video_link': 'Paste link to Personal Brand Video here...',
  'portfolio.i_am_video_description':
    'Introduce your Personal Brand Video here...',
  'portfolio.promo_video_link': 'Paste link to Brand Intro video here…',
  'portfolio.promo_video_description':
    'Introduce your Brand Introductory video here...',
  'portfolio.article_link': 'Browse to upload pitch deck PDF.',
  'portfolio.article_description': 'Introduce your Pitch Deck here...',
  'portfolio.save_changes': 'Save Changes',
  'portfolio.edit_who_am_i': 'EDIT WHO AM I?',
  'portfolio.edit_modal_first_paragrf':
    'Edit your name, occupation, and social media',
  'portfolio.edit_modal_second_paragrf': 'in your account settings.',
  'portfolio.publish_checkbox':
    'Publish your portfolio to share your story with the global community.',
  'portfolio.my_portfolio_edit':
    'Share your empowerment, wellness, and performance with the global community',
  'portfolio.edit_top_skills': 'EDIT TOP SKILLS',
  'portfolio.skills_title': 'TOP SKILLS',
  'portfolio.add_personal_skill_error': 'You have alredy this skill',
  'portfolio.add_personal_skill_success': 'Skill saved successfully',
  'portfolio.Suggested_Skills_Based_on_My_Profile':
    'Suggested Skills Based on My Profile',
  'portfolio.Add_Skills': 'Add Skills',
  'portfolio.Type_skill': 'Type skill (Example: coaching)',
  'portfolio.LICENSES_CERTIFICATIONS': 'LICENSES & CERTIFICATIONS',
  'portfolio.upload_new_license_image': 'Upload Image',
  'portfolio.add_new_Certified': 'Name (Example: Certified Interior Designer)',
  'portfolio.add_new_Certified_Issuing_Authority':
    'Issuing Authority (Example: Council for Interior Design Accreditation (CIDA))',
  'portfolio.add_new_Certified_Date_Issued': 'Date Issued',
  'portfolio.add_new_Certified_Credential_ID': 'Credential ID',
  'portfolio.add_new_Certified_Credential URL': 'Credential URL',
  'portfolio.Add_Licenses_or_Certifications': 'Add Licenses or Certifications',
  'portfolio.all_field_needed': 'All fields are needed',
  'portfolio.EDIT_LICENSES_CERTIFICATIONS': 'EDIT LICENSES & CERTIFICATIONS',
  'portfolio.file_type':
    'File Types: .png or .jpg only Dimensions: 150 x 150px Size: 500KB max.',
  'portfolio.remove_license': 'Remove Licenses or Certifications',
  'portfolio.add_new_certificate': 'Click here to add new certificate',
  'portfolio.Publish.My.Portfolio': 'Publish My Portfolio',
  'portfolio.confirmation_modal':
    'Are you sure you want to make your portfolio public?',
  'portfolio.confirmation_modal_second_part':
    'We’re excited you’re ready to publish! We wanted to remind you that once published, anyone on the Internet can potentially view the information in your portfolio.',
  //Beyond Your Course
  'beyond_your_course.page_description':
    'Encouragement and guidance to help you on your journey.',
  'beyond_your_course.encouragement_videos': 'Encouragement Videos',
  'beyond_your_course.encouragement_no_videos': 'Encouragement',
  'beyond_your_course.master_classes': 'Master Classes',
  'beyond_your_course.Career_Guidance': 'Career Guidance',
  'beyond_your_course.master_classes_upper': 'MASTER CLASSES',
  'beyond_your_course.encouragement': 'Encouragement',
  'beyond_your_course.encouragement_description':
    'Videos from the founder and CEO of The Startup Studio to help you on your journey.',
  'beyond_your_course.master_classes_description':
    'Ongoing guidance from entrepreneurs and experts to help you on your journey.',

  'video.from_nothing_to_demand': 'From Nothing To Demand',
  'video.understanding_the_reality_of_execution':
    'Understanding the reality of execution.',
  'video.taking_a_new_perspective': 'Gaining a New Perspective',
  'video.understanding_a_broader_perspective_as_you_look_at_who_you_are_in_the_world':
    ' Understanding a broader perspective as you look at who you are in the world.',
  'video.give_yourself_a_break': 'Give Yourself a Break',
  'video.understanding_the_power_of_time_and_patience':
    'Understanding the power of time and patience.',
  'video.happiness_is_overrated': 'Happiness is Overrated',
  'video.understanding_the_difference_between_happiness_and_wellness':
    'Understanding the difference between happiness and wellness.',
  'video.relationship_is_key': 'Relationships are Key',
  'video.understand_that_it_all_begins_with_people':
    'Understanding that it all begins with people.',
  'video.rethinking_time': 'Rethinking Time',
  'video.understanding_that_time_is_not_a_linear_concept':
    'Understanding that time is not a linear concept.',
  'video.the_myth_of_entrepreneurship': 'The Myth of Innovation',
  'video.understanding_the_real_meaning_of_innovation_compared_to_ideation':
    'Understanding the real meaning of innovation compared to ideation.',
  'video.the_power_of_story_and_failure': 'The Power of Story & Failure',
  'video.understanding_that_you_must_be_seen_and_that_failure_is_the_key_to_success':
    'Understanding that you must be seen, and that failure is the key to success.',
  'video.understanding_risk': 'Understanding Risk',
  'video.understanding_what_it_really_means_and_why_you_must_overcome_the_fear_of_it':
    'Understanding what it really means and why you must overcome the fear of it.',
  'video.becoming_a_fear_less_leader': 'Becoming a Fear-Less Leader',
  'video.becoming_a_fear_less_leader_entrepreneur': 'Cindy Schooler',
  'video.forms_of_legal_organization': 'Forms of Legal Organization',
  'video.forms_of_legal_organization_entrepreneur': 'Jim Hunter',
  'video.managing_financial_risk_in_entrepreneurship':
    'Managing Financial Risk in Entrepreneurship',
  'video.managing_financial_risk_in_entrepreneurship_entrepreneur': 'Joe Gitto',
  'video.building_saas_solutions_that_scale':
    'Building SaaS Solutions That Scale',
  'video.building_saas_solutions_that_scale_entrepreneur': 'Ridvan Aliu',
  'video.creating_an_international_startup':
    'Creating an International Startup',
  'video.creating_an_international_startup_entrepreneur': 'Sander Eijkenduijn',
  'video.now_playing': 'Now playing:',
  'video.Failure_as_an_Imperative': 'Failure as an Imperative.',
  'video.introduction_to_the_balance_sheet':
    ' Introduction to the Balance Sheet',
  'video.introduction_to_the_profit_and_loss_sheet':
    'Introduction to the Profit and Loss Sheet',
  'video.introduction_to_cash_flow_analysis':
    'Introduction to Cash Flow Analysis',

  //Startup live
  'startup_live.page_description':
    'Welcome to our live webinar where we invite our entire market community to interact with our learners',

  'startup_live.starting_in': 'Starting In',
  'startup_live.join_session': 'Join Session',
  'startup_live.now_streaming': 'Now streaming',
  'startup_live.startup_archive': 'StartupLive™ Archive',
  'startup_live.startup_archive_description': 'Watch the video Q&As here.',

  //My notes
  'my_notes.page_title': 'My Notes',
  'my_notes.success_added': 'Your note has been saved successfully',
  'my_notes.page_description': 'Find, review, and edit notes',
  'my_notes.search': 'Search notes...',
  'my_notes.sort_by_date_created': 'Sort By Date Created',
  'my_notes.sort_by_date_edited': 'Sort By Date Edited',
  'my_notes.not_started': 'NOT STARTED',
  'my_notes.sample_note': 'Sample Note',
  'my_notes.edited': 'Edited:',
  'my_notes.created_in': 'Created in:',
  'my_notes.created_month': 'MY COURSE: Month',
  'my_notes.edit_note': 'Edit Note',
  'my_notes.save_note': 'Save Note',
  'my_notes.back': 'Back',
  'my_notes.meeting_room_note': 'My Meeting Room Note',
  'my_notes.leave_without_saving':
    'Are you sure you want to leave without saving?',
  'my_notes.go_back': 'Oops, go back!',
  'my_notes.leave_anyway': 'Leave anyway.',
  'my_notes.add_button': 'ADD NEW NOTE',
  'my_notes.notes_title': 'Note Title',
  'my_notes.notes_title_neddet': 'You must insert a title',
  'my_notes.notes_title_text': 'Notes Text needed',
  'my_notes.notes_modal_body': 'Write your note here ...',
  'my_notes.note_title_needed': 'Note title needed',
  'my_notes.note_description_needed': 'Note description needed',
  'my_notes.add_a_new_note': 'Add a new note',

  // Instructor notes
  'instructor_notes.success_added': 'Your note has been saved successfully',
  'instructor_notes.notes_title': 'Add a subject',
  'instructor_notes.notes_title_neddet': 'You must insert a title',
  'instructor_notes.notes_title_text': 'Notes Text needed',

  //My journals
  'my_journal.lts_title': 'MY LEARN TO START JOURNAL',
  'my_journal.lts_description':
    'The LTS model is a guide in the lifelong process of developing your ability to find agency (empowerment) which creates a healthy understanding of yourself (wellness) allowing you to manifest yourself by taking specific actions (performance), and developing the entrepreneurial mindset.',
  'my_journal.wellness_title': 'MY WELLNESS JOURNAL',
  'my_journal.wellness_description':
    'Wellness is planning, acting, and responding with self-awareness with the intent of becoming your best self. Use this journal to reflect on your wellness journey and treat it as a lifelong process.',
  'my_journal.personal-finance_title': 'MY PERSONAL FINANCE JOURNAL',
  'my_journal.personal-finance_description':
    'Financial literacy is understanding and using knowledge and tools to manage your finances. Use this journal to reflect on your personal finance journey and treat it as a lifelong process.',
  'my_journal.market-ready_title': 'MY MARKET-READY JOURNAL',
  'my_journal.market-ready_description': ' ',
  'my_journal.entrepreneurship_title': 'MY COURSE IN ENTREPRENEURSHIP',
  'my_journal.entrepreneurship_description':
    'Embarking on the Entrepreneurial Journey',
  'my_journal.hs1_title': 'LTS 1',
  'my_journal.hs1_description':
    'Welcome to LTS 1. Here you will find the curriculum for this level broken down into tasks that you will guide students through. You will also find the space to reflect on your experience through each of these tasks.',
  'my_journal.hs2_title': 'LTS 2',
  'my_journal.hs2_description':
    'Welcome to LTS 2. Here you will find the curriculum for this level broken down into tasks that you will guide students through. You will also find the space to reflect on your experience through each of these tasks.',
  'my_journal.hs3_title': 'LTS 3',
  'my_journal.hs3_description':
    'Welcome to LTS 3. Here you will find the curriculum for this level broken down into tasks that you will guide students through. You will also find the space to reflect on your experience through each of these tasks.',
  'my_journal.hs4_title': 'LTS 4',
  'my_journal.hs4_description':
    'Welcome to LTS 4. Here you will find the curriculum for this level broken down into tasks that you will guide students through. You will also find the space to reflect on your experience through each of these tasks.',
  'my_journal.my-training_title': 'MY TRAINING',
  'my_journal.my-training_description':
    'Welcome to your LTS Training. Here you will be introduced to what it means to be a LTS Instructor and you will prepare for your experience in this new type of classroom.',
  'my_journal.mentorship_title': 'MY MENTORSHIP JOURNAL PAGE',
  'my_journal.mentorship_description':
    'The Learn to Start Model is a guide in the lifelong process of developing the answers to the only three questions that matter: Who am I? What can I do? How do I prove it? You will use this journal to guide your development to those answers.',

  'my_journal.not_started_journal': 'NOT STARTED',
  'my_journal.edit_journal': 'Edit Journal',
  'my_journal.save_journal': 'Save Journal',
  'my_journal.search_journals': 'Search journals',
  'my_journal.created_page': 'My Journal:',

  //Reset passsword
  'alert.fill_password_fields': 'Please fill password fields',
  'reset.reset_your_password': 'Reset Your Password',
  'reset.new_password': 'New Password',
  'reset.confirm_new_password': 'Confirm New Password',
  'reset.password_field_empty': 'Password filed is empty',
  'reset.password_not_match': "The password you type doesn't match",
  'reset.password_conform_policy':
    'Password must contain at least 8 numbers and/or characters and it should have at least one number, lowercase & uppercase character',

  //Journals
  'journals.cancel': 'Cancel',
  'journals.delete': 'Delete',
  'journals.save': 'Save',
  'journals.add': 'Add Reflection',
  'journals.started': 'Started:',
  'journals.edited': 'Edited:',

  //Journals
  'journals.category_1': 'PURPOSE',
  'journals.category_2': 'CONFIDENCE',
  'journals.category_3': 'RELATIONSHIPS',
  'journals.category_4': 'BALANCE',
  'journals.category_5': 'DEVELOPMENT',
  'journals.category_6': 'OPPORTUNITY',
  'journals.category_7': 'ENERGY',
  'journals.category_8': 'INFLUENCES',
  'journals.category_9': 'ASPIRATIONS',
  'journals.category_10': 'RESOURCES',
  'journals.purpose_text':
    'In the space below you can reflect on each question as often as you determine helpful. Start with an initial reflection at the beginning of your journey, and return at times of frustration, achievement, excitement, and insecurity. You can modify any of your reflections or add a new one at any time..',
  'journals.cat_1_question_1':
    'What is the reason you are doing what you’re doing?',
  'journals.cat_1_question_2': 'Why is this important to you?',
  'journals.cat_2_question_1': 'What is your level of confidence?',
  'journals.cat_2_question_2': 'What strengthens your confidence?',
  'journals.cat_2_question_3': 'What diminishes your confidence?',
  'journals.cat_3_question_1': 'How are you building new relationships?',
  'journals.cat_3_question_2':
    'How are you influenced by the judgment of others?',
  'journals.cat_3_question_3': 'How are you maintaining your relationships?',
  'journals.cat_3_question_4': 'How are you evaluating your relationships?',
  'journals.cat_4_question_1': 'What brings you happiness?',
  'journals.cat_4_question_2': 'Do your commitments conflict with each other?',
  'journals.cat_4_question_3':
    'Do you feel like you’re missing out on any aspect of your life?',
  'journals.cat_5_question_1':
    'How do you track your growth in different areas of your life?',
  'journals.cat_5_question_2':
    'How do you talk to yourself when you make mistakes and when you achieve your goals?',
  'journals.cat_5_question_3':
    'Are you engaging in any self-destructive behaviors?',
  'journals.cat_6_question_1':
    'Are you seeing opportunities or are you seeing obstacles?',
  'journals.cat_6_question_2':
    'How do you evaluate yourself as a problem-solver?',
  'journals.cat_7_question_1': 'Do you have consistent sleep patterns?',
  'journals.cat_7_question_2': 'Do you maintain a healthy/balanced diet?',
  'journals.cat_7_question_3': 'Are you physically active?',
  'journals.cat_8_question_1': 'Who currently has the power to influence you?',
  'journals.cat_8_question_2': 'What currently has the power to influence you?',
  'journals.cat_9_question_1':
    'What version of yourself are you striving to be?',
  'journals.cat_9_question_2':
    'What do you want your life to be like in the future?',
  'journals.cat_10_question_1':
    'Do you have what you need to do what you want to do?',
  'journals.cat_10_question_2':
    'Who and what do you rely on to support yourself and your endeavors?',
  'journals.cat_10_question_3': 'Who and what do you want to rely on?',
  'journals.started': 'Started:',
  'journals.add_new_journal': 'ADD NEW REFLECTION',

  //Profile
  'profile.cancel_subscription': 'Cancel My Subscription',
  'profile.are_you_canceling_subscription':
    'Are you sure you want to cancel your subscription?',
  'profile.cancel_subscription_text':
    'Once you cancel you will lose access to the platform and any saved work.',
  'profile.keep_me_subscribed': 'No, Keep Me SUBSCRIBED',
  'profile.cancel_my_subscription': 'YES Cancel my subscription',
  'profile.unsubscribed':
    'You have successfully been unsubscribed. We’re sorry to see you go!',
  'profile.unsubscribed_text':
    'You can login and resubscribe anytime within the next 90 days to pick up where you left off. After 90 days your information will be deleted and all your course progress will be lost.',
  'profile.incorrect_number':
    'Your number format is incorrect. It must start with + then number ',

  //My account
  'my_account.page_title': 'MY ACCOUNT',
  'my_account.page_description': 'Access and edit your account information',
  'my_account.update_password': 'Update My Password',
  'my_account.email_address': 'EMAIL ADDRESS',
  'my_account.edit_my_bio': 'EDIT MY BIO',
  'my_account.first_last_name': 'First & Last Name',
  'my_account.position': 'Current Position (25 character limit)',
  'my_account.type_position_placeholder':
    'Type current position or role here (Example: Digital Video Producer)',
  'my_account.type_position': 'Type current role or position',
  'my_account.upload_new_image': 'Upload New Image',
  'my_account.edit_social_media': 'Edit Social Media Links',
  'my_account.edit_my_summary': 'Edit My Summary',
  'my_account.edit_contact_information': 'Edit Contact Information',
  'my_account.mobile_phone': 'Mobile phone',
  'my_account.edit_contact_information_info':
    '**Mobile phone informaiton is only used for notification and will not be visible to others',
  'my_account.contact_information': 'Contact information',
  'my_account.summary_placeholder':
    'Type or paste your professional bio here. This will be public, so do not include anything that you aren’t comfortable sharing, please.',
  'my_account.original_password': 'Original Password',
  'my_account.new_password': 'New Password',
  'my_account.repeat_new_password': 'Repeat New Password',
  'alert.my_account.password_change_success':
    'Your password has been changed successfully',
  'my_account.password_change_success':
    'Your password has been changed successfully',
  'my_account.success_change': 'Your data has been changed successfully',
  'alert.my_account.success_change': 'Your data has been changed successfully',
  'my_account.link_to_my_Portfolio_text': 'Link to My Portfolio. ',
  'my_account.link_to_my_Portfolio_span': ' (click to copy)',
  'my_account.share_my_profile': 'Share My Profile',
  'my_account.share_my_portfolio': 'Share portfolio',
  'my_account.profile_tags_title': 'Profile Tags',
  'my_account.profile_description':
    'You haven’t added any profile tags… yet! Click the box below to add some so people can find you more easily in My Community.',
  'my_account.edit_my_profile_tags': 'EDIT MY PROFILE TAGS',
  'my_account.edit_my_profile_tags_select': 'Select Your Profile Tags',
  'my_account.edit_my_profile_tags_select_up':
    'Profile tags help us to identify other users who might be of interest to you. You can use them to filter Recommended Connections to find more people to connect to. In the box below, type in a word that describes you or your interests. Available tags will appear for you to select. Or, you may create a new tag. You may add up to 5 tags.',
  'my_account.edit_my_profile_tags_search_box':
    'Type tag (Examples: entrepreneur, computers)',
  'my_account.edit_my_profile_select_tags': 'Selected Profile Tags:',
  'my_account.add_my_personal_profile_tags': 'Add your personal tag ',
  'my_account.add_my_profile_tags': 'Type below your tag you want to add ',
  'my_account.add_my_profile_tags_more': 'More than 5 tags are not allowed',
  //Chat
  'chat.engage_with_comunity': 'ENGAGE WITH YOUR ONLINE COMMUNITY',
  'chat.there_are': 'There are ',
  'chat.other_participants':
    ' other participants online right now. Connect with them below.',

  //Events
  'event.to_be_announced': 'To Be Announced',
  'event.welcome_to_LTS': 'Welcome to your Learn to Start Live Classroom',
  'event.welcome_to_LTS_date_time': 'August 30, 2022 | 4:30PM EST',
  'event.welcome_to_LTS_date': 'April 14, 2021',
  'event.understanding_LTS':
    'Understanding Learn to Start - Meet The Startup Studio Founder and CEO, Gary Conroy',
  'event.understanding_LTS_date_time': 'APRIL 29, 2021 | 4:30PM EST',
  'event.understanding_LTS_date': 'April 29, 2021',
  'event.anastasia_hall': 'Anastasia Hall',
  'event.gary_conroy': 'Gary Conroy',
  'event.welcome_to_LTS_1':
    'Welcome to your Learn to Start Live Classroom | March 09, 2021',
  'event.welcome_to_LTS_2':
    'Welcome to your Learn to Start Live Classroom | June 06, 2021',
  'event.understanding_LTS_1':
    'Understanding Learn to Start - Meet The Startup Studio Founder and CEO, Gary Conroy | March 24,2021',

  //Modals
  'modal.contact_us': 'Contact Support',
  'modal.contact_us_email': 'Your email',
  'modal.contact_us_subject_label': 'Subject',
  'modal.contact_us_subject_placeholder': 'Subject',
  'modal.contact_us_message_label': 'Message',
  'modal.contact_us_message_placeholder': 'Add your message here…',
  'modal.contact_us_send_message': 'Send message',
  'modal.Public_Portfolio_link_will_automatically_appear_here':
    '(Public Portfolio link will automatically appear here)',

  //My Account
  'my_account.page_title': 'MY ACCOUNT',
  'my_account.page_description': 'Access and edit your account information',
  'my_account.update_password': 'Update My Password',
  'my_account.email_address': 'EMAIL ADDRESS',
  'my_account.edit_my_bio': 'EDIT MY BIO',
  'my_account.first_last_name': 'First & Last Name',
  'my_account.position': 'Current Position (25 character limit)',
  'my_account.type_position': 'Type current role or position',
  'my_account.upload_new_image': 'Upload New Image',
  'my_account.edit_social_media': 'Edit Social Media Links',
  'my_account.edit_my_summary': 'Edit My Summary',
  'my_account.edit_contact_information': 'Edit Contact Information',
  'my_account.mobile_phone': 'Mobile phone',
  'my_account.edit_contact_information_info':
    '**Mobile phone informaiton is only used for notification and will not be visible to others',
  'my_account.contact_information': 'Contact information',
  'my_account.summary_placeholder':
    'Type or paste your professional bio here. This will be public, so do not include anything that you aren’t comfortable sharing, please.',
  'my_account.original_password': 'Original Password',
  'my_account.new_password': 'New Password',
  'my_account.repeat_new_password': 'Repeat New Password',
  'my_account.password_change_success':
    'Your password has been changed successfully',
  'my_account.success_change': 'Your data has been changed successfully',

  //Tags
  'tag.architecture': 'Architecture',
  'tag.entrepreneur': 'Entrepreneur',
  'tag.software': 'Software',

  //Subscription Ended
  'subscription.subscription_ended': 'Your Subscription Has Ended!',
  'subscription.resubscribe': 'To continue your journey, please resubscribe.',
  'subscription.charge':
    'Each month your card will be charged $15. You may cancel at any time.',
  'subscription.trial_ended': 'Your Free Trial Has Ended!',
  'subscription.subscribe': 'To continue your journey, please subscribe.',

  //Connections
  'connection.accept_connection': 'ACCEPT REQUEST',
  'connection.ignore_connection': 'IGNORE REQUEST',
  'connection.block_user': 'BLOCK USER',
  'connection.request.successful':
    'Your connection request was sent succesfully',
  'connection.accept_request':
    'You have successfully accepted the connection request',
  'connection.page_title': 'My Connections',
  'connection.page_description': 'Your Learn to Start Peers',
  'connection.my_requests': 'My Connection Requests',
  'connection.recommended_requests': 'Recommended Connections',
  'connection.no_requests':
    'You don’t have any connection requests yet! Get started by searching for your peers in the search box above.',
  'connection.no_connections':
    'You don’t have any connections yet! Get started by searching for your peers in the search box above.',
  'connection.search_community': 'SEARCH YOUR COMMUNITY',
  'connection.user_blocked':
    'This user has been blocked. You will no longer see connection requests or messages from this user.',
  'connection.request_ignored':
    'This request has been ignored. You will no longer see it in your Connection Requests; however, the user may send you another request later.',
  'connection.title': 'connections',
  //Messenger
  'messenger.search_message': 'SEARCH MESSAGES',

  //Alerts
  'alerts.name_required': 'Name is required',
  'alerts.email_required': 'Email is required',
  'alerts.password_required': 'Password is required',
  'alerts.subject_required': 'Please enter a subject',
  'alerts.valid_email': 'Please enter a valid email address',
  'alerts.contact_message': 'Please enter a message.',
  'alerts.email_password_incorrect': 'Your email and/or password is incorrect',
  'alerts.fill_password_fields': 'Please fill password fields',
  'alerts.password_confirm_password_do_not_match':
    'Password and confirm password do not match!',
  'alerts.password_conform_policy':
    'Password did not conform with policy: Password must have at least one number, lowercase & uppercase character',
  'alert.incorrect_password': 'Your password is incorrect',
  'alerts.community_code_required': 'Community Code is required',
  'alerts.wrong_community_code': 'Wrong community Code!',
  'alerts.enrolled_successfully': 'You have been enrolled successfully!',
  'alerts.user_exist': 'User already exist!',
  'alerts.card_holder_name_required': 'Card holder name is required',
  'alerts.no_such_coupon': 'There is no such coupon, please write a valid one',
  'alerts.coupon_expired': 'Coupon expired',
  'alerts.incomplete_number': 'Your card number is incomplete',
  'alerts.incomplete_cvc': "Your card's security code is incomplete",
  'alerts.invalid_number': 'Your card number is invalid',
  'alerts.incomplete_expiry': "Your card's expiration date is incomplete",
  'alerts.invalid_credit_card':
    'Your card card is invalid, please check your entry!',
  'alert.password_min_8': 'Password must be at least 8 characters',
  'alert.password_limit_exceededs':
    'Attempt limit exceeded, please try after some time.',
  // 'alert.check_email_redirect':
  //   'Please check your email for the reset password link!',
  'alert.check_email_redirect':
    'Please check your email for the verification code!',
  'alerts.something_went_wrong': 'Something went wrong, please try again!',
  'alert.my_account.success_change': 'Your data has been changed successfully',
  'alert.my_account.password_change_success':
    'Your password has been changed successfully',
  'alerts.subscribed': 'You’ve successfully subscribed!',
  'alerts.success_change': 'Your changes has been saved successfully',
  // Story in motion
  'navigation.course_month_1_lesson_2': 'The Myths of Entrepreneurship',
  'sidebar.story_in_motion': 'STORY IN MOTION®',
  'storyInMotion.page_description':
    'A Learn to Start Podcast - Conversations on Empowerment, Wellness and Performance.',
  'storyInMotion.page_description2':
    'A Learn to Start Podcast - Exploring who you are and why it matters',
  'storyInMotion.page_week_podcast': 'THIS WEEK’S PODCAST',
  'storyInMotion.page_week_now_playing': 'NOW PLAYING:',
  // 'storyInMotion.page_also_avalible':
  //   'Also available on your favorite streaming platform:',
  'storyInMotion.page_also_avalible':
    'Also available on your favorite streaming platform',
  'storyInMotion.previous_podcast_episodes': 'Previous Podcast Episodes',
  'storyInMotion.favorite': 'Favorite Episodes',
  'storyInMotion.load_more': 'Load more...',
  'storyInMotion.guest_q&a_videos': 'Story in Motion Guest Q&As',
  // SAVED page
  'my_saved.MEDIA': 'MY SAVED MEDIA',
  'my_saved.MEDIA_des': 'Your favorite content in one place.',
  'my_saved.MEDIA_VIDEOS': 'VIDEOS',
  'my_saved.MEDIA_PODCASTS': 'PODCASTS',
  'my_saved.MEDIA_PODCASTS': 'PODCAST',
  'my_saved.STORY_IN_MOTION': 'STORY IN MOTION',
  'my_saved.not_saved_PODCASTS': 'No saved podcast!',
  'my_saved.alredy_saved_PODCASTS': 'Podcast is alredy saved',
  'my_saved.alredy_saved_PODCASTS_note': 'Note podcast page',
  'foul_words.notice':
    'We have detected foul words in your writing, please revise your text before submitting. Your account can be suspended if foul words are used. Contact us if you have any questions!',

  // CALENDAR TASK-EVENTS

  'calendar_task-events.add_a_new_task/event': 'Add a new task/event',
  'calendar_task-events.edit_task': 'Edit task',
  'calendar_task-events.edit_event': 'Edit event',
  'calendar_task-events.name_of_task': 'Name of task',
  'calendar_task-events.date_of_task': 'Date of task',
  'calendar_task-events.description_of_task': 'Description of task',
  'calendar_task-events.name_of_event': 'Name of event',
  'calendar_task-events.date_of_event': 'Date of event',
  'calendar_task-events.description_of_event': 'Description of event',
  'calendar_task-events.requirements': 'Requirements',
  'calendar_task-events.choose_classes': 'Choose classes',
  'calendar_task-events.chosen_classes': 'Chosen classes',
  'calendar_task-events.event': 'Event',
  'calendar_task-events.task': 'Task',
  'calendar_task-events.start_date_of_task': 'Start date of task',
  'calendar_task-events.end_date_of_task': 'End date of task',
  'calendar_task-events.start_time_of_task': 'Start time of task',
  'calendar_task-events.end_time_of_task': 'End time of task',
  'calendar_task-events.start_date_of_event': 'Start date of event',
  'calendar_task-events.end_date_of_event': 'End date of event',
  'calendar_task-events.start_time_of_event': 'Start time of event',
  'calendar_task-events.end_time_of_event': 'End time of event',
  'student_journals.student-lts_title': 'LEARN TO START JOURNAL',
  'student_journals.student-lts_description':
    'The Learn to Start Model is a guide in the lifelong process of developing the answers to the only three questions that matter: Who am I? What can I do? How do I prove it? You will use this journal to guide your development to those answers.',
  'student_journals.student-wellnes_title': 'WELLNESS JOURNAL',
  'student_journals.student-wellnes_description':
    'Wellness is planning, acting, and responding with self-awareness with the intent of becoming your best self. Use this journal to reflect on your wellness journey and treat it as a lifelong process.',
  'student_journals.student-personal-finance_title': 'PERSONAL FINANCE JOURNAL',
  'student_journals.student-personal-finance_description':
    'In order to be market-ready, you must be financially literate. This journal will help you gain the knowledge and skills you need to make informed financial decisions.',
  'student_journals.student-leadership_title': 'LEADERSHIP JOURNAL',
  'student_journals.student-leadership_description':
    'Leadership comes in many forms but the foundation is leading yourself first. Use this journal to inspire your development as a leader.',

  // My SPARK
  'my_spark.input-label_name': 'Name',
  'my_spark.input-label_title': 'Title',
  'my_spark.input-label_keywords': 'Keywords',
  'my_spark.input-label_subheadings': 'Subheadings',
  'my_spark.input-label_length': 'Length',
  'my_spark.input-label_language': 'Language',
  'my_spark.input-label_creativity': 'Creativity',
  'my_spark.input-label_variations': 'Variations',

  'my_spark.input-placeholder_name': 'Name',
  'my_spark.input-placeholder_title': 'The best summer destinations',
  'my_spark.input-placeholder_keywords': 'ocean, beach, hotel',
  'my_spark.input-placeholder_subheadings':
    'Florida, Los Angeles, San Francisco',
  'my_spark.input-placeholder_length': 'Medium',
  'my_spark.input-placeholder_language': 'English',
  'my_spark.input-placeholder_creativity': 'Original',
  'my_spark.input-placeholder_variations': '1',

  'my_spark.input-description_name': 'The name of the document.',
  'my_spark.input-description_title': 'The title of the article',
  'my_spark.input-description_keywords': 'The keywords to include.',
  'my_spark.input-description_subheadings': 'The subheadings of the article.',
  'my_spark.input-description_length': 'The length result.',
  'my_spark.input-description_language':
    'The language in which the result to be returned.',
  'my_spark.input-description_creativity': 'The creative level of result.',
  'my_spark.input-description_variations':
    'The number of variations of results.'
}

export default EnLangs
